jQuery(function ($) {

    let $select = $('#category-select');
    if ( !$select.length ) {
        return;
    }

    $select.select2({
        minimumResultsForSearch: -1,
    });

    $select.on('change', function () {
        window.location.href = $select.val();
    });

});