ThemeJS.Lazy_Images = (function ( $ ) {

    var observer;

    function init () {
        let images = document.querySelectorAll('.lazy-image, .lazy-bg');
        let config = {
            // If the image gets within 100px in the Y axis, start the download.
            rootMargin: '200px 0px',
            threshold: 0.01
        };

        // The observer for the images on the page
        observer = new IntersectionObserver( onIntersection, config );
        for ( let i = 0; i < images.length; i++ ) {
            observer.observe( images[i] );
        };
    }

    function onIntersection( entries ) {
        // Loop through the entries
        for ( let i = 0; i < entries.length; i++ ) {
            let entry = entries[i];
            // Are we in viewport?
            if ( entry.intersectionRatio > 0 ) {
        
                // Stop watching and load the image
                observer.unobserve( entry.target );
                preloadImage( entry.target );
            }
        };
    }

    function preloadImage( target ) {
        let $target = $(target);
        let data = $target.data();
        if ( $target.is( 'img' ) ) {
            ['srcset', 'src'].forEach( attr => {
                if ( data.hasOwnProperty( attr ) ) {
                    $target.attr( attr, data[ attr ] );
                }
            });
        } else if ( data.hasOwnProperty( 'bg_src' ) ) {
            $target.css( 'background-image', `url( ${ data.bg_src } )` );
        }
        $target.removeClass('lazy-image lazy-bg');
    }

    $( init );

    return {
        init
    };

})( jQuery );