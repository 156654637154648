(function ($) {

    let $search_form;
    let $search_toggle;

    let $search_input;
    let $close_search;

    // set domain for youtube origins
    let url = window.location.href;
    let url_arr = url.split("/");
    let domain = url_arr[0] + "//" + url_arr[2];

    // video resizing
    let $hero_bg_video_wrap;
    let $hero_bg_video;
    let bg_video_size;

    function open_search_form () {
        $search_form.addClass('open');
        setTimeout(function () {
            // timeout required to prevent shifting of background img
            // time matches $transition-timing sass var
            $search_input.focus();
        }, 300);
    }

    function close_search_form () {
        $search_input.val('');
        $search_input.blur();
        $search_form.removeClass('open');
    }

    window.onYouTubeIframeAPIReady = function () {
        init_video_modal();
    }

    function init_video_modal () {
        $( 'div.hero-video-modal' ).each( function () {
            let video_id = $( this ).data().videoid;
            let video_container_id = `hero-video-${ video_id }`;
            let modal_video_player = new YT.Player( video_container_id, {
                height: '1080',
                width: '1920',
                videoId: video_id,
                playerVars: {
                    'controls': 1,
                    'autoplay': 0,
                    'mute': 0,
                    'loop': 0,
                    'showinfo': 0,
                    'modestbranding': 1,
                    'origin': domain
                },
            });
            $( `#${ video_container_id }` ).closest( '.youtube-iframe-wrap' ).fixYoutubeIframeSize();
            $(this).on( 'hide.bs.modal', function () {
                modal_video_player.pauseVideo();
            });
        });
        $( document ).on( 'click', 'a.hero-video-link', function (e) {
            e.preventDefault();
            let video_id = $(this).attr( 'data-videoid' );
            $( `div.hero-video-modal[data-videoid=${ video_id }` ).modal( 'show' );
        });
    }

    function init_background_video () {
        $hero_bg_video_wrap = $('.hero-background-video-wrap');
        if ( !$hero_bg_video_wrap.length || $(window).width() < 768 ) {
            return;
        }
        let src = $hero_bg_video_wrap.data().video_src;
        let type = $hero_bg_video_wrap.data().video_type;

        bg_video_size = {
            width: $hero_bg_video_wrap.data().video_width,
            height: $hero_bg_video_wrap.data().video_height
        };
        
        let $source = $( '<source>' ).attr( 'src', src ).attr( 'type', `video/${ type }` );
        $hero_bg_video = $( '<video autoplay loop muted>' ).append( $source );
        $hero_bg_video_wrap.append( $hero_bg_video );
        resize_bg_video();
        $hero_bg_video_wrap.fadeIn( 500 );
        $(window).resize( resize_bg_video );
    }

    function resize_bg_video () {
        if ( !$hero_bg_video.length ) { return; }
        let display_size = {
            width: $hero_bg_video_wrap.width(),
            height: $hero_bg_video_wrap.height()
        };
        let video_ar = bg_video_size.width / bg_video_size.height;
        let container_ar = display_size.width / display_size.height;
        
        if ( container_ar <= video_ar ) {
            // set height to container and calculate width
            $hero_bg_video.height( display_size.height );
            $hero_bg_video.width( display_size.height * video_ar );
        } else {
            // set width to container and calculate height
            $hero_bg_video.width( display_size.width );
            $hero_bg_video.height( display_size.width / video_ar );
        }
    }

    function fix_flex_min_height_bug () {
        let $section = $( 'section.hero_banner' );
        if ( $section.length && ThemeJS.Functions.browser_has_flex_bug() ) {
            ThemeJS.Functions.fix_element_flex_bug( $section[0] );
            $(window).resize( function () {
                ThemeJS.Functions.fix_element_flex_bug( $section[0] );
            });
        }
    }

    $(function () {
        fix_flex_min_height_bug();
        $search_form = $( '.hero-search-form' );
        $search_toggle = $( '.hero-search a' );
        if ( $search_form.length && $search_toggle.length ) {
            $search_input = $( '#search-field', $search_form );
            $close_search = $( '.close-search', $search_form );
            $search_toggle.click( open_search_form );
            $close_search.click( close_search_form );
        }
        init_background_video();
    });

})( jQuery );