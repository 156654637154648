jQuery( function ($) {

    $(document).on( 'submit', '.gform_wrapper form', function () {
        let $button = $( 'button[type=submit]', this );
        if ( !$button.length ) { return; }
        $button.attr( 'disabled', true );
        $button.attr( 'aria-disabled', 'true' );
        $button.addClass( 'is-ajax-submitting' );
    });

});