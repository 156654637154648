(function ($) {

    let $header;
    let $body;
    let $menu_toggle;
    let $primary_menu;
    let $primary_menu_items;
    let submenu_active = false;
    let $window = $(window);

    function set_opacity () {
        $header.toggleClass( 'opaque', ( $window.scrollTop() != 0 ) );
    }

    function set_active_sub_menu ( menu_item_id = false, transition_duration = 300 ) {
        submenu_active = Boolean( menu_item_id );
        if ( submenu_active ) {
            $primary_menu_items.removeClass( 'sub-menu-active' );
            $primary_menu_items.filter( `#${ menu_item_id }` ).each( function () {
                $(this).addClass( 'sub-menu-active' );
            });
        }
        $primary_menu.css({
            transition: `all ${ transition_duration}ms ease`
        });
        $primary_menu.toggleClass( 'has-active-sub-menu', submenu_active );
        // $primary_menu.fadeOut( transition_duration, function () {
        //     $primary_menu.toggleClass( 'has-active-sub-menu', Boolean( menu_item_id ) );
        //     $primary_menu_items.removeClass( 'sub-menu-active' );
        //     $primary_menu_items.filter( `#${ menu_item_id }` ).each( function () {
        //         $(this).addClass( 'sub-menu-active' );
        //     });
        //     $primary_menu.fadeIn( transition_duration );
        // });
    }

    $(function () {
        $header = $('header.site-header');
        $body = $('body');
        $menu_toggle = $('.menu-toggle');
        $primary_menu = $('#menu-primary-menu');
        $primary_menu_items = $( '.menu-item', $primary_menu );
        if ( $('main > section:first-child').is('.hero_banner') ) {
            set_opacity();
            $window.scroll( set_opacity );
        } else {
            $header.addClass( 'opaque' );
            $('main').css({
                paddingTop: $header.height()
            });
        }
        
        $menu_toggle.click( function () {
            if ( submenu_active ) {
                set_active_sub_menu();
                return;
            }
            $body.toggleClass( 'menu-open' );
            if ( !$body.hasClass( 'menu-open' ) ) {
                set_active_sub_menu( false, 0 );
            }
        });

        $( '.toggle-sub-menu', $primary_menu ).click( function () {
            let $menu_item = $(this).closest( '.menu-item' );
            let id = $menu_item.length ? $menu_item.attr('id') : false;
            set_active_sub_menu( id );
        });

        let resize_debounce;
        $window.resize( function () {
            clearTimeout( resize_debounce );
            resize_debounce = setTimeout( function () {
                if ( $window.width() >= 1200 ) {
                    $body.removeClass( 'menu-open' );
                    set_active_sub_menu( false, 0 );
                }
            }, 10 );
        });

    });

})( jQuery );