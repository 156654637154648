/*--------------------------------------------*\
    Include Globally Useful JS Functions
\*--------------------------------------------*/

ThemeJS.Functions = (function ( $, root ) {

    function send_ajax_request ( args ) {
        args.data.nonce = ThemeJS.Variables.Ajax.nonce;
        $.post( ThemeJS.Variables.Ajax.url, args.data, function ( response ) {
            response = JSON.parse( response );
            if ( !response.hasOwnProperty( 'data' ) ) { response.data = {}; }
            if ( response.success && typeof args.success == 'function' ) {
                args.success( response.data );
            } else if ( !response.success && typeof args.error == 'function' ) {
                args.error( response.data );
            }
        });
    }

    function get_query_parameter( name, url ) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    // following 2 functions taken from taken from https://stackoverflow.com/questions/25177791/are-flexbox-and-vh-height-units-not-compatible-in-ie11
    function browser_has_flex_bug () {
        var outer = document.createElement('div');
        var inner = document.createElement('div');
        outer.setAttribute('style', 'display:-ms-flexbox; display:flex; min-height:100vh;');
        outer.appendChild(inner);
        (document.body || document.documentElement).appendChild(outer);
        var bug = !inner.offsetHeight;
        outer.parentNode.removeChild(outer);
        return bug;
    }
    function fix_element_flex_bug ( el ) {
        el.style.height = 'auto';
        var height = el.offsetHeight;
        el.style.height = height + 'px';
    }


    $(document).on('mouseenter', '.tribe-events-calendar-month__calendar-event', function () {
        setTimeout( function () {
            $('.tooltipster-base').clone().appendTo('body');
        }, 1000 );
    });


    $( document ).on( 'click', 'a[href^=\\#]', function ( e ) {
        let href = $(this).attr('href');
        let target = { href: href };
        $( document ).trigger( 'pre_scroll_to_target', target );
        let $target = $( target.href );
        if ( $target.length ) {
            e.preventDefault();
            $( 'html, body' ).animate({
                scrollTop: $target.offset().top - 125
            }, 1000 );
        }
    });



    return {
        send_ajax_request,
        get_query_parameter,
        browser_has_flex_bug,
        fix_element_flex_bug
    }






})( jQuery, this );
