(function ($){

    $('section.logos_slider').each( function () {
        let $slider = $( '.slick-slider', this );
        let $slider_links = $( '.slider-control', this );

        if ( !$slider.length || !$slider_links.length ) { return; }

        let slick_options = {
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
            focusOnSelect: false,
            initialSlide: $slider_links.filter( '.active' ).data().slide
        };

		$slider.slick( slick_options );
        
		$slider_links.click(function() {
			let slide_nav = $(this).attr('data-slide');
			$slider.slick( 'slickGoTo', slide_nav );
        });
        
		$slider.on('beforeChange', function( event, slick, currentSlide, nextSlide ) {
            let $slider_link = $slider_links.eq( nextSlide );
            $slider.trigger( 'activate_slide', $slider_link.data().slidename );
        });
        
        $slider.on( 'activate_slide', function ( e, slide ) {
            let $slider_link = $slider_links.filter( `[data-slidename=${ slide }]` );
            if ( $slider_link.length ) {
                $slider_links.removeClass("active");
                $slider_link.addClass("active");
                if ( $(window).width() < 768 ) {
                    $('html, body').animate({
                        scrollTop: $slider.offset().top - 100
                    }, 500);
                }
            }
        });
    });

    $( document ).on( 'pre_scroll_to_target', function ( e, target ) {
        let split_href = target.href.split('--');
        if ( split_href.length > 1 && split_href[1] ) {
            let $target_slider = $( `.logos_slider${ split_href[0] }` );
            if ( $target_slider.length ) {
                target.href = split_href[0];
                let $slider_link = $( `.slider-control[data-slidename=${ split_href[1] }]`, $target_slider );
                if ( $slider_link.length ) {
                    $slider_link.click();
                }
            }
        }
    });

})( jQuery );